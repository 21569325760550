import React from 'react';
import { Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { SubcategoryTableItem } from '../../../types/catalog';
import styles from '../common/Table.module.css';

interface SubcategoryTableProps {
    data: SubcategoryTableItem[];
    onRowClick: (id: string) => void;
}

const SubcategoryTable: React.FC<SubcategoryTableProps> = ({ data, onRowClick }) => {
    const columns: ColumnsType<SubcategoryTableItem> = [
        {
            title: 'Название',
            dataIndex: 'title',
            key: 'title',
            width: '60%',
        },
        {
            title: 'Курсы',
            dataIndex: 'coursesCount',
            key: 'coursesCount',
            width: '40%',
            render: (count: number) => (
                <Tag color="blue">{count}</Tag>
            ),
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            onRow={(record) => ({
                onClick: () => onRowClick(record.key),
                className: styles.tableRow,
            })}
        />
    );
};

export default SubcategoryTable;