import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

const ForbiddenPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="403"
      title="403"
      subTitle="У вас нет доступа к этой странице"
      extra={
        <Button type="primary" onClick={() => navigate('/login')}>
          Войти в систему
        </Button>
      }
    />
  );
};

export default ForbiddenPage;