import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useCourses } from '../../hooks/courses/useCourses';
import { CoursesTable } from '../../components/Courses';
import commonStyles from '../../styles/CommonPage.module.css';
import styles from './Catalog.module.css';

const { Title } = Typography;

const CoursesPage: React.FC = () => {
  const navigate = useNavigate();
  const { courses } = useCourses();

  // Фильтруем курсы без id и затем сортируем
  const sortedCourses = [...(courses || [])]
    .filter(course => course?.id) // фильтруем undefined и null
    .sort((a, b) => {
      if (!a?.id || !b?.id) return 0;
      return b.id.localeCompare(a.id);
    });

  const handleRowClick = (courseId: string) => {
    navigate(`/catalog/courses/${courseId}`);
  };

  return (
    <div className={commonStyles.pageContainer}>
      <div className={styles.pageHeader}>
        <Title level={2} className={commonStyles.pageTitle}>
          Курсы
        </Title>
        <Button 
          type="primary" 
          icon={<PlusOutlined />}
          onClick={() => {/* TODO: Добавить создание курса */}}
          className={styles.createButton}
        >
          Создать курс
        </Button>
      </div>

      <div className={commonStyles.pageContent}>
        <CoursesTable
          data={sortedCourses}
          onRowClick={handleRowClick}
        />
      </div>
    </div>
  );
};

export default CoursesPage;