import api from './base';
import { UploadedFile, FilePurpose } from '../../types/files';

export const uploadFile = async (file: File, purpose: FilePurpose): Promise<UploadedFile> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('purpose', purpose);

  const response = await api.post('/files/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};