import React, { useState, useEffect } from 'react';
import { Drawer, Button, Spin } from 'antd';
import { Form } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Subcategory, CreateSubcategoryRequest } from '../../../types/catalog';
import SubcategoryForm from './SubcategoryForm';
import CourseCard from '../common/CourseCard';
import { Course, CourseShort } from '../../../types/courses/course';
import { useCourses } from '../../../hooks/courses/useCourses';
import { useQueryClient } from '@tanstack/react-query';


interface SubcategoryDrawerProps {
  visible: boolean;
  subcategory: Subcategory | null;
  onClose: () => void;
  onSubmit: (values: CreateSubcategoryRequest) => Promise<void>;
  loading: boolean;
  validationErrors: Record<string, string>;
}

const SubcategoryDrawer: React.FC<SubcategoryDrawerProps> = ({
  visible,
  subcategory,
  onClose,
  onSubmit,
  loading,
  validationErrors,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [form] = Form.useForm();
  const { courses } = useCourses();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!visible) {
      setIsEditing(false);
      form.resetFields();
    }
  }, [visible, form]);

  useEffect(() => {
    if (subcategory && isEditing) {
      form.setFieldsValue({
        title: subcategory.title,
        courseIds: subcategory.courses?.map(course => course.id) || []
      });
    }
  }, [subcategory, isEditing, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      
      const formattedValues: CreateSubcategoryRequest = {
        title: values.title,
        courses: values.courseIds.map((courseId: string, index: number) => ({
          id: courseId,
          position: index + 1
        }))
      };
  
      await onSubmit(formattedValues);
      
      if (subcategory) {
        queryClient.invalidateQueries({ queryKey: ['subcategory', subcategory.id] });
        queryClient.invalidateQueries({ queryKey: ['allSubcategories'] });
        queryClient.invalidateQueries({ queryKey: ['categories'] });
      }
      
      setIsEditing(false);
    } catch (error) {
      console.error('Ошибка при отправке формы:', error);
    }
  };

  const handleClose = () => {
    onClose();
    setIsEditing(false);
    form.resetFields();
  };

  const handleEdit = () => {
    setIsEditing(true);
    form.setFieldsValue({
      title: subcategory?.title,
      courseIds: subcategory?.courses?.map(course => course.id) || []
    });
  };

  const getTitle = () => {
    if (!subcategory) return 'Создать подкатегорию';
    return isEditing ? 'Редактировать подкатегорию' : 'Детали подкатегории';
  };

  const renderDetails = () => {
    if (!subcategory) return null;
  
    const fullCourses = subcategory.courses?.map((shortCourse: CourseShort) => {
      const fullCourse = courses.find((c: Course) => c.id === shortCourse.id);
      return fullCourse ? { ...fullCourse, position: shortCourse.position } : null;
    }).filter((course): course is Course & { position: number } => course !== null);
  
    return (
      <div>
        <p><strong>Название:</strong> {subcategory.title}</p>
        
        <div style={{ marginBottom: 24 }}>
          <div style={{ 
            marginBottom: 8,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <strong>Курсы</strong>
            {subcategory.courses && subcategory.courses.length > 0 && (
              <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                Всего: {subcategory.courses.length}
              </span>
            )}
          </div>
  
          <div>
            {fullCourses && fullCourses.length > 0 ? (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                {fullCourses.map((course) => (
                  <CourseCard
                    key={course.id}
                    course={course}
                    onClick={() => {}} 
                  />
                ))}
              </div>
            ) : (
              'Нет курсов'
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Drawer
      title={getTitle()}
      placement="right"
      onClose={handleClose}
      open={visible}
      width={400}
      footer={
        (isEditing || !subcategory) ? (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Отмена
            </Button>
            <Button type="primary" onClick={handleSubmit} loading={loading}>
              {subcategory ? 'Сохранить' : 'Создать'}
            </Button>
          </div>
        ) : (
          <Button type="primary" icon={<EditOutlined />} onClick={handleEdit}>
            Редактировать
          </Button>
        )
      }
    >
      {loading && !isEditing ? (
        <Spin tip="Загрузка..." />
      ) : (isEditing || !subcategory) ? (
        <SubcategoryForm
          form={form}
          validationErrors={validationErrors}
        />
      ) : (
        renderDetails()
      )}
    </Drawer>
  );
};

export default SubcategoryDrawer;