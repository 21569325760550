import React from 'react';
import { Card, List, Spin, Divider } from 'antd';
import { useAllSubcategories } from '../../../hooks/useAllSubcategories';
import { Subcategory } from '../../../types/catalog';

interface SubcategorySelectorProps {
  selectedSubcategories: Subcategory[];
  setSelectedSubcategories: (subcategories: Subcategory[]) => void;
}

const SubcategorySelector: React.FC<SubcategorySelectorProps> = ({
  selectedSubcategories = [],
  setSelectedSubcategories,
}) => {
  const { data: allSubcategories, isLoading } = useAllSubcategories();

  const handleSelect = (subcategory: Subcategory) => {
    if (selectedSubcategories.find(s => s.id === subcategory.id)) {
      setSelectedSubcategories(selectedSubcategories.filter(s => s.id !== subcategory.id));
    } else {
      setSelectedSubcategories([...selectedSubcategories, subcategory]);
    }
  };

  if (isLoading) {
    return <Spin tip="Загрузка подкатегорий..." />;
  }

  const sortedSubcategories = [
    ...selectedSubcategories,
    ...(allSubcategories || []).filter(s => !selectedSubcategories.find(sel => sel.id === s.id))
  ];

  return (
    <div>
      <h3>Выберите подкатегории</h3>
      <List
        dataSource={sortedSubcategories}
        split={false}
        renderItem={(subcategory, index) => (
          <>
            {index === selectedSubcategories.length && selectedSubcategories.length > 0 && (
              <Divider>Все подкатегории</Divider>
            )}
            <List.Item
              onClick={() => handleSelect(subcategory)}
              style={{
                padding: 0,
                marginBottom: '4px',
                cursor: 'pointer',
                width: '100%'
              }}
            >
              <Card
                size="small"
                style={{
                  width: '100%',
                  borderColor: selectedSubcategories.find(s => s.id === subcategory.id) ? '#1677ff' : undefined
                }}
              >
                <strong>{subcategory.title}</strong>
                <span style={{ float: 'right', color: 'rgba(0, 0, 0, 0.45)' }}>
                  Курсов: {subcategory.courses?.length || 0}
                </span>
              </Card>
            </List.Item>
          </>
        )}
      />
    </div>
  );
};

export default SubcategorySelector;