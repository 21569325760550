import React from 'react';
import { Menu } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { menuItems } from './menuItems';
import classNames from 'classnames';
import styles from './styles.module.css';

export const SidebarMenu: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getOpenKeys = () => {
    const pathParts = location.pathname.split('/');
    if (pathParts.length >= 2) {
      return [pathParts[1]];
    }
    return [];
  };

  // Определяем, находимся ли мы в деталях
  const isInDetails = location.pathname.split('/').length > 3;

  const getSelectedKey = () => {
    const path = location.pathname;
    if (path.startsWith('/catalog/courses/')) {
      return 'catalog/courses';
    }
    return path.substring(1) || 'dashboard';
  };

  return (
    <Menu
      theme="dark"
      mode="inline"
      className={classNames(styles.menu, {
        [styles.menuInDetails]: isInDetails
      })}
      defaultOpenKeys={getOpenKeys()}
      selectedKeys={[getSelectedKey()]}
      items={menuItems}
      onClick={({ key }) => navigate(`/${key}`)}
    />
  );
};