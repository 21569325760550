import React from 'react';
import { Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useAuth } from '../../hooks/useAuth';
import { LogoutButtonProps } from './types';
import styles from './styles.module.css';

export const LogoutButton: React.FC<LogoutButtonProps> = ({ collapsed }) => {
  const { logout } = useAuth();

  return (
    <div className={styles.logoutContainer}>
      <Button
        type="text"
        icon={<LogoutOutlined />}
        onClick={logout}
        className={styles.logoutButton}
      >
        {!collapsed && 'Выйти'}
      </Button>
    </div>
  );
};