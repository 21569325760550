import React from 'react';
import { Card, Typography, Button } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { Course } from '../../../types/courses/course';
import styles from './CourseCard.module.css';

const { Text } = Typography;

interface CourseCardProps {
  course: Course;
  selected?: boolean;
  onClick?: () => void;
}

const CourseCard: React.FC<CourseCardProps> = ({ course, selected, onClick }) => {
  const handleOpenDetails = (e: React.MouseEvent) => {
    e.stopPropagation();
    window.open('https://google.com', '_blank');
  };

  return (
    <Card 
      size="small"
      className={styles.card}
      onClick={onClick}
      style={{ 
        borderColor: selected ? '#1677ff' : undefined,
        cursor: 'pointer'
      }}
    >
      <div className={styles.cardContent}>
        {course.image && (
          <div className={styles.imageContainer}>
            <img
              alt={course.title}
              src={course.image}
              className={styles.image}
            />
          </div>
        )}
        <div className={styles.info}>
          <div className={styles.header}>
            <strong>{course.title}</strong>
            <Button
              type="text"
              size="small"
              icon={<ExportOutlined />}
              onClick={handleOpenDetails}
            />
          </div>
          <Text type="secondary">
            {course.price === 0 ? 'Бесплатно' : `${course.price} ₽`}
          </Text>
        </div>
      </div>
    </Card>
  );
};

export default CourseCard;