import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { createCategory, getAllCategories, updateCategory } from '../../services/api';
import { Category, CreateCategoryRequest } from '../../types/catalog';

export const useCategories = () => {
  const queryClient = useQueryClient();

  const { data: categories = [] } = useQuery<Category[]>({
    queryKey: ['categories'],
    queryFn: getAllCategories,
  });

  const createMutation = useMutation({
    mutationFn: createCategory,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['categories'] });
      message.success('Категория создана');
    },
    onError: (error: any) => {
      message.error('Ошибка при создании категории');
      return error;
    }
  });

  const updateMutation = useMutation({
    mutationFn: ({ id, data }: { id: string; data: CreateCategoryRequest }) => 
      updateCategory(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['categories'] });
      message.success('Категория обновлена');
    },
    onError: (error: any) => {
      message.error('Ошибка при обновлении категории');
      return error;
    }
  });

  return {
    categories,
    createCategory: createMutation.mutateAsync,
    updateCategory: updateMutation.mutateAsync,
    isLoading: createMutation.isPending || updateMutation.isPending
  };
};