import React, { useEffect, useState } from 'react';
import { Drawer, Button, Form, Input, InputNumber, message, Space, Modal } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteCategory, updateCategory } from '../../../services/api';
import { Category, CreateCategoryRequest, Subcategory, SubcategoryShort } from '../../../types/catalog';
import styles from '../../Files/FileUpload.module.css';
import FileUpload from '../../Files/FileUpload';
import SubcategoryCard from '../common/SubcategoryCard';
import SubcategorySelector from '../common/SubcategorySelector';
import { useAllSubcategories } from '../../../hooks/useAllSubcategories';

interface CategoryDrawerProps {
  visible: boolean;
  category?: Category | null;
  onClose: () => void;
  onSubmit?: (values: CreateCategoryRequest) => Promise<void>;
  loading?: boolean;
  validationErrors?: Record<string, string>;
}

const CategoryDrawer: React.FC<CategoryDrawerProps> = ({
  visible,
  category,
  onClose,
  onSubmit,
  loading,
  validationErrors,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [selectedSubcategories, setSelectedSubcategories] = useState<Subcategory[]>([]);
  const { data: allSubcategories = [] } = useAllSubcategories();

  const deleteMutation = useMutation({
    mutationFn: (categoryId: string) => deleteCategory(categoryId),
    onSuccess: () => {
      message.success('Категория удалена');
      queryClient.invalidateQueries({ queryKey: ['categories'] });
      onClose();
    },
    onError: () => {
      message.error('Ошибка при удалении категории');
    },
  });

  const updateMutation = useMutation({
    mutationFn: (data: CreateCategoryRequest) => 
      updateCategory(category!.id, data),
    onSuccess: async (updatedCategory) => {
      message.success('Категория обновлена');
      
      // Инвалидируем все связанные запросы
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['categories'] }),
        queryClient.invalidateQueries({ queryKey: ['allSubcategories'] }),
        queryClient.invalidateQueries({ queryKey: ['subcategory'] })
      ]);

      // Обновляем данные в кэше
      queryClient.setQueryData(['categories'], (oldData: Category[] = []) => {
        return oldData.map(cat => 
          cat.id === updatedCategory.id ? updatedCategory : cat
        );
      });

      // Обновляем данные конкретной категории
      queryClient.setQueryData(['category', category!.id], updatedCategory);
      
      setIsEditing(false);
    },
    onError: () => {
      message.error('Ошибка при обновлении категории');
    },
  });

  useEffect(() => {
    if (!visible) {
      setIsEditing(false);
      setSelectedSubcategories([]);
      form.resetFields();
    }
  }, [visible, form]);

  useEffect(() => {
    if (category && !isEditing) {
      queryClient.setQueryData(['category', category.id], category);
    }
  }, [category, isEditing, queryClient]);

  useEffect(() => {
    if (category && isEditing && allSubcategories.length > 0) {
      form.setFieldsValue({
        title: category.title,
        position: category.position,
        image: category.image,
      });
  
      const initialSelected = (category.subcategories || [])
        .map(sub => allSubcategories.find(fullSub => fullSub.id === sub.id))
        .filter((sub): sub is Subcategory => sub !== undefined);
      
      setSelectedSubcategories(initialSelected);
    } else if (!isEditing) {
      setSelectedSubcategories([]);
    }
  }, [category, isEditing, allSubcategories, form]);

  const handleDelete = () => {
    Modal.confirm({
      title: 'Вы уверены, что хотите удалить эту категорию?',
      onOk: () => category && deleteMutation.mutate(category.id),
    });
  };

  const handleEdit = () => {
    if (category) {
      setIsEditing(true);
    }
  };

  const handleSave = async (values: CreateCategoryRequest) => {
    const subcategories: SubcategoryShort[] = selectedSubcategories.map((subcategory, index) => ({
      id: subcategory.id,
      position: index + 1,
    }));

    const data: CreateCategoryRequest = {
      ...values,
      subcategories: subcategories.length > 0 ? subcategories : null,
    };

    if (category) {
      await updateMutation.mutateAsync(data);
    } else if (onSubmit) {
      await onSubmit(data);
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setSelectedSubcategories([]);
    form.resetFields();
  };

  const handleClose = () => {
    onClose();
    setIsEditing(false);
    setSelectedSubcategories([]);
    form.resetFields();
  };

  const getTitle = () => {
    if (!category) return 'Создание категории';
    return isEditing ? 'Редактирование категории' : 'Просмотр категории';
  };

  return (
    <Drawer
      title={getTitle()}
      placement="right"
      onClose={handleClose}
      open={visible}
      width={400}
      footer={
        <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {category && !isEditing ? (
            <>
              <Button 
                icon={<DeleteOutlined />} 
                danger 
                onClick={handleDelete}
              >
                Удалить
              </Button>
              <Button 
                type="primary" 
                icon={<EditOutlined />} 
                onClick={handleEdit}
              >
                Редактировать
              </Button>
            </>
          ) : (
            <>
              <Button onClick={handleCancelEdit}>
                Отменить
              </Button>
              <Button 
                type="primary" 
                onClick={() => form.submit()} 
                loading={loading || updateMutation.isPending}
              >
                Сохранить
              </Button>
            </>
          )}
        </Space>
      }
    >
      {(isEditing || !category) ? (
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSave}
          initialValues={category ? {
            title: category.title,
            position: category.position,
            image: category.image,
          } : undefined}
        >
          <Form.Item
            label="Название"
            name="title"
            validateStatus={validationErrors?.title ? 'error' : ''}
            help={validationErrors?.title}
            rules={[
              { required: true, message: 'Введите название категории' },
              { min: 2, message: 'Минимальная длина - 2 символа' },
              { max: 30, message: 'Максимальная длина - 30 символов' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Позиция"
            name="position"
            validateStatus={validationErrors?.position ? 'error' : ''}
            help={validationErrors?.position}
            rules={[
              { required: true, message: 'Укажите позицию' },
              { type: 'number', min: 1, message: 'Позиция должна быть больше 0' },
            ]}
          >
            <InputNumber min={1} style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            label="Изображение"
            name="image"
            validateStatus={validationErrors?.image ? 'error' : ''}
            help={validationErrors?.image}
            rules={[{ required: true, message: 'Загрузите изображение' }]}
          >
            <FileUpload purpose="category_image" />
          </Form.Item>

          <SubcategorySelector
            selectedSubcategories={selectedSubcategories}
            setSelectedSubcategories={setSelectedSubcategories}
          />
        </Form>
      ) : (
        <div>
          <p><strong>Название:</strong> {category.title}</p>
          <p><strong>Позиция:</strong> {category.position}</p>
          <div style={{ marginBottom: 24 }}>
            <div style={{ marginBottom: 8 }}>
              <strong>Изображение:</strong>
            </div>
            <div className={styles.fileCard}>
              <img 
                src={category.image} 
                alt={category.title}
                className={styles.fileCardImage}
              />
            </div>
          </div>

          <div style={{ marginBottom: 24 }}>
            <div style={{ 
              marginBottom: 16,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <strong>Подкатегории</strong>
              {category.subcategories && category.subcategories.length > 0 && (
                <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                  Всего: {category.subcategories.length}
                </span>
              )}
            </div>
            <div>
              {category.subcategories?.length ? (
                category.subcategories.map((subcategory) => (
                  <SubcategoryCard 
                    key={subcategory.id} 
                    subcategoryId={subcategory.id} 
                  />
                ))
              ) : (
                'Нет подкатегорий'
              )}
            </div>
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default CategoryDrawer;