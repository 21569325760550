import React, { useRef, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Layout, Menu, Spin, Button, message, Typography } from 'antd';
import { AppstoreOutlined, ReadOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useCourseDetails } from '../../hooks/courses/useCourseDetails';
import { updateCourse } from '../../services/api/courses';
import { Course, CourseBase, UpdateCourseRequest } from '../../types/courses/course';
import { useModules } from '../../hooks/modules/useModules';
import { useLessonsDetails } from '../../hooks/lessons/useLessonDetails';
import { Module } from '../../types/courses/module';
import { updateModule } from '../../services/api/modules';
import { Lesson, LessonShort } from '../../types/courses/lesson';
import GeneralSection from '../../components/Courses/courses/GeneralSection';
import LessonsSection from '../../components/Courses/common/LessonsSection';
import commonStyles from '../../styles/CommonPage.module.css';
import styles from './CourseDetailsPage.module.css';

const { Content, Sider } = Layout;
const { Title } = Typography;

interface ModuleWithLessons extends Module {
  lessons: LessonShort[];
}

const CourseDetailsPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { courseId = '' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedSection, setSelectedSection] = React.useState(
    searchParams.get('tab') || 'general'
  );
  const [selectedModuleId, setSelectedModuleId] = React.useState<string | null>(
    searchParams.get('moduleId') || null
  );
  const [selectedLessonId, setSelectedLessonId] = React.useState<string | null>(
    searchParams.get('lessonId') || null
  );

  // Загружаем данные курса один раз
  const { 
    data: courseData, 
    isLoading: isCourseLoading,
    isError,
    error,
    refetch 
  } = useCourseDetails(courseId, true);

  React.useEffect(() => {
    if (courseId) {
      refetch();
    }
  }, [courseId, refetch]);

  // Добавим больше логов
  console.log('CourseId:', courseId);
  console.log('CourseData:', courseData);
  console.log('IsLoading:', isCourseLoading);
  console.log('IsError:', isError);
  console.log('Error:', error);

  // Проверяем наличие modules перед использованием
  const lessonIds = React.useMemo(() => {
    if (!courseData?.modules) {
      console.log('No modules found in courseData:', courseData);
      return [];
    }
    return (courseData.modules as ModuleWithLessons[]).flatMap(module => {
      console.log('Processing module:', module);
      return module.lessons?.map((lesson: LessonShort) => lesson.id) || [];
    });
  }, [courseData]);

  // Загружаем все уроки одним запросом
  const { 
    data: lessons = [], 
    isLoading: isLessonsLoading,
    refetch: refetchLessons
  } = useLessonsDetails(
    lessonIds,
    selectedSection === 'lessons'
  );

  // Загружаем все модули только при поиске
  const { 
    data: allModules = [], 
    isLoading: isModulesLoading
  } = useModules(selectedModuleId === 'search');

  const formRef = useRef<{ submit: () => void }>();

  useEffect(() => {
    const params: Record<string, string> = { tab: selectedSection };
    if (selectedModuleId) params.moduleId = selectedModuleId;
    if (selectedLessonId) params.lessonId = selectedLessonId;
    setSearchParams(params);
  }, [selectedSection, selectedModuleId, selectedLessonId, setSearchParams]);

  const handleModuleSelect = (moduleId: string | null) => {
    setSelectedModuleId(moduleId);
  };

  const handleLessonSelect = (lessonId: string | null) => {
    setSelectedLessonId(lessonId);
  };

  const handleModuleUpdate = async (moduleId: string, values: Partial<Module>) => {
    try {
      const updatedModule = await updateModule(moduleId, values);
      if (courseData) {
        const updatedModules = courseData.modules.map((module: Module) => 
          module.id === moduleId ? updatedModule : module
        );
        const updatedCourse = {
          ...courseData,
          modules: updatedModules
        };
        queryClient.setQueryData(['course', courseId], updatedCourse);
        queryClient.invalidateQueries({
          queryKey: ['module', moduleId]
        });
        queryClient.invalidateQueries({
          queryKey: ['modules']
        });
      }
    } catch (error) {
      message.error('Ошибка при обновлении модуля');
    }
  };

  const handleLessonUpdate = async (updatedLesson: Lesson) => {
    await refetchLessons();
  };

  const handleSave = async (values: UpdateCourseRequest) => {
    try {
      await updateCourse(courseId, values);
      message.success('Изменения сохранены');
    } catch (error) {
      message.error('Не удалось сохранить изменения');
    }
  };

  const handleSectionChange = (key: string) => {
    setSelectedSection(key);
  };

  const handleModulesUpdate = (updatedModules: Module[]) => {
    if (courseData) {
      const updatedCourse = {
        ...courseData,
        modules: updatedModules
      };
      // Обновляем кэш react-query
      queryClient.setQueryData(['course', courseId], updatedCourse);
    }
  };

  const menuItems = [
    {
      key: 'general',
      icon: <AppstoreOutlined />,
      label: 'Общее',
    },
    {
      key: 'lessons',
      icon: <ReadOutlined />,
      label: 'Уроки',
    },
  ];

  const isLoading = React.useMemo(() => {
    if (isCourseLoading) return true;
    if (selectedSection === 'lessons' && isLessonsLoading) return true;
    if (selectedModuleId === 'search' && isModulesLoading) return true;
    return false;
  }, [
    isCourseLoading,
    selectedSection,
    isLessonsLoading,
    selectedModuleId,
    isModulesLoading
  ]);

  if (isLoading || !courseData) {
    return (
      <div className={commonStyles.loadingContainer}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Layout className={styles.pageLayout}>
      <Sider width={200} className={styles.sectionSider} theme="light">
        <div className={styles.siderContent}>
          <Menu
            mode="inline"
            selectedKeys={[selectedSection]}
            items={menuItems}
            onClick={({ key }) => handleSectionChange(key)}
            className={styles.sectionMenu}
          />
          <div className={styles.siderFooter}>
            <Button 
              icon={<ArrowLeftOutlined />}
              onClick={() => navigate('/catalog/courses')}
            />
            <Button 
              type="primary"
              onClick={() => formRef.current?.submit()}
            >
              Сохранить
            </Button>
          </div>
        </div>
      </Sider>
      <Content className={styles.pageContent}>
        <Title level={2} className={commonStyles.pageTitle}>
          {selectedSection === 'general' ? 'Общее' : 'Уроки'}
        </Title>
        {selectedSection === 'general' ? (
          <GeneralSection 
            course={courseData as CourseBase}
            onSave={handleSave}
            formRef={formRef}
          />
        ) : (
          <LessonsSection
            courseId={courseId}
            modules={courseData.modules || []}
            lessons={lessons}
            course={courseData as Course}
            onModuleUpdate={handleModuleUpdate}
            onLessonUpdate={handleLessonUpdate}
            selectedModuleId={selectedModuleId}
            selectedLessonId={selectedLessonId}
            onModuleSelect={handleModuleSelect}
            onLessonSelect={handleLessonSelect}
            allModules={selectedModuleId === 'search' ? allModules : undefined}
            onModulesUpdate={handleModulesUpdate}
          />
        )}
      </Content>
    </Layout>
  );
};

export default CourseDetailsPage;