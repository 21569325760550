import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { RouteErrorBoundary } from '../components/RouteErrorBoundary';
import LoginPage from '../pages/auth/LoginPage';
import ProtectedRoute from '../components/ProtectedRoute';

import MainLayout from '../layouts/MainLayout';
import CategoriesPage from '../pages/catalog/CategoriesPage';
import CoursesPage from '../pages/catalog/CoursesPage';
import DashboardPage from '../pages/dashboard/DashboardPage';
import ProfilePage from '../pages/profile/ProfilePage';
import CourseDetailsPage from '../pages/catalog/CourseDetailsPage';


export const routes: RouteObject[] = [
  {
    errorElement: <RouteErrorBoundary />,
    children: [
      {
        path: '/login',
        element: <LoginPage />,
      },
      {
        path: '/',
        element: (
          <ProtectedRoute>
            <MainLayout />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="/dashboard" replace />,
          },
          {
            path: 'dashboard',
            element: <DashboardPage />,
          },
          {
            path: 'catalog/categories',
            element: <CategoriesPage />,
          },
          {
            path: 'catalog/courses',
            children: [
              {
                index: true,
                element: <CoursesPage />,
              },
              {
                path: ':courseId',
                element: <CourseDetailsPage />,
              },
            ],
          },
          {
            path: 'profile',
            element: <ProfilePage />,
          },
        ],
      },
      {
        path: '*',
        element: <RouteErrorBoundary />,
      },
    ],
  },
];