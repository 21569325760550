import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateModule } from '../../services/api/modules';
import { Module } from '../../types/courses/module';

export const useModuleUpdate = (courseId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ moduleId, values }: { moduleId: string; values: Partial<Module> }) => 
      updateModule(moduleId, values),
    onSuccess: (updatedModule) => {
      // Обновляем кэш модуля
      queryClient.setQueryData(['module', updatedModule.id], updatedModule);

      // Обновляем кэш курса
      queryClient.setQueryData(['course', courseId], (oldCourse: any) => {
        if (!oldCourse) return oldCourse;

        return {
          ...oldCourse,
          modules: oldCourse.modules.map((module: Module) =>
            module.id === updatedModule.id ? updatedModule : module
          ),
        };
      });

      // Инвалидируем связанные запросы
      queryClient.invalidateQueries({ queryKey: ['modules'] });
      queryClient.invalidateQueries({ queryKey: ['course', courseId] });
    },
  });
};