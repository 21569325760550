import React from 'react';
import { Card, Typography, Button, Space } from 'antd';
import { PlusOutlined, DownOutlined, UpOutlined, RightOutlined } from '@ant-design/icons';
import { Module } from '../../../types/courses/module';
import { Lesson } from '../../../types/courses/lesson';
import styles from './ModuleCard.module.css';

const { Text } = Typography;

interface ModuleCardProps {
  module: Module;
  onClick?: () => void;
  onAdd?: (module: Module) => void;
  isExpanded?: boolean;
  lessons?: Lesson[];
}

const ModuleCard: React.FC<ModuleCardProps> = ({ 
  module, 
  onClick, 
  onAdd,
  isExpanded,
  lessons = []
}) => {
  const handleAddClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onAdd?.(module);
  };

  const handleToggleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick?.();
  };

  return (
    <Card 
      className={styles.moduleCard}
    >
      <div className={styles.moduleContent}>
        <div className={styles.moduleHeader}>
          <Space direction="vertical" size={4} style={{ flex: 1 }}>
            <Text strong>{module.title}</Text>
            <Text type="secondary">
              Уроков: {module.lessons?.length || 0}
            </Text>
          </Space>
          <Button
            type="text"
            icon={<PlusOutlined />}
            onClick={handleAddClick}
          />
        </div>

        {isExpanded && (
          <div className={styles.lessonsList}>
            {module.lessons?.map(lessonRef => {
              const lesson = lessons.find(l => l.id === lessonRef.id);
              if (!lesson) return null;
              
              return (
                <div 
                  key={lesson.id}
                  className={styles.lessonItem}
                >
                  <Text>{lesson.title}</Text>
                  <RightOutlined className={styles.arrowIcon} />
                </div>
              );
            })}
          </div>
        )}

        <Button
          type="text"
          className={styles.toggleButton}
          onClick={handleToggleClick}
          icon={isExpanded ? <UpOutlined /> : <DownOutlined />}
        >
          {isExpanded ? 'Скрыть уроки' : 'Показать уроки'}
        </Button>
      </div>
    </Card>
  );
};

export default ModuleCard; 