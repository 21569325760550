import React from 'react';
import { Table, Tag, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { CheckOutlined } from '@ant-design/icons';
import { Course } from '../../../types/courses/course';
import styles from '../common/Table.module.css';

interface CoursesTableProps {
  data: Course[];
  onRowClick: (id: string) => void;
}

const CoursesTable: React.FC<CoursesTableProps> = ({ data, onRowClick }) => {
  const getLessonsCount = (course: Course) => {
    let modulesCount = 0;
    let lessonsCount = 0;

    course.modules?.forEach(module => {
      if (module.lessons?.length) {
        modulesCount++;
        lessonsCount += module.lessons.length;
      }
    });

    return `${modulesCount} ${getModuleWord(modulesCount)}, ${lessonsCount} ${getLessonWord(lessonsCount)}`;
  };

  const getModuleWord = (count: number) => {
    const cases = [2, 0, 1, 1, 1, 2];
    const titles = ['модуль', 'модуля', 'модулей'];
    return titles[
      count % 100 > 4 && count % 100 < 20 
        ? 2 
        : cases[Math.min(count % 10, 5)]
    ];
  };

  const getLessonWord = (count: number) => {
    const cases = [2, 0, 1, 1, 1, 2];
    const titles = ['урок', 'урока', 'уроков'];
    return titles[
      count % 100 > 4 && count % 100 < 20 
        ? 2 
        : cases[Math.min(count % 10, 5)]
    ];
  };

  const columns: ColumnsType<Course> = [
    {
      title: 'Название',
      dataIndex: 'title',
      key: 'title',
      width: '30%',
      ellipsis: true,
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
      className: styles.descriptionColumn,
      render: (text: string) => (
        <Tooltip title={text}>
          <div className={styles.description}>
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'Изображение',
      dataIndex: 'image',
      key: 'image',
      width: '140px',
      align: 'center',
      render: (image: string) => (
        image ? <CheckOutlined style={{ color: '#52c41a' }} /> : null
      ),
    },
    {
      title: 'Стоимость',
      dataIndex: 'price',
      key: 'price',
      width: '120px',
      render: (price: number) => (
        price === 0 ? 'Бесплатно' : `${price} ₽`
      ),
    },
    {
      title: 'Уроки',
      key: 'lessons',
      width: '150px',
      render: (_, record) => (
        <Tag color="blue">{getLessonsCount(record)}</Tag>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data.map(course => ({ ...course, key: course.id }))}
      pagination={false}
      onRow={(record) => ({
        onClick: () => onRowClick(record.id),
        className: styles.tableRow,
      })}
      className={styles.table}
    />
  );
};

export default CoursesTable;