import api from './base';
import { Category, Subcategory, CreateCategoryRequest, CreateSubcategoryRequest } from '../../types/catalog';

// Категории
export const createCategory = async (data: CreateCategoryRequest): Promise<Category> => {
  const response = await api.post('/categories/create', data);
  return response.data;
};

export const getAllCategories = async (): Promise<Category[]> => {
  const response = await api.get('/categories/all');
  return response.data;
};

export const updateCategory = async (categoryId: string, data: CreateCategoryRequest): Promise<Category> => {
  const response = await api.patch(`/categories/${categoryId}`, data);
  return response.data;
};

export const deleteCategory = async (categoryId: string): Promise<void> => {
  await api.delete(`/categories/${categoryId}`);
};

// Подкатегории
export const getAllSubcategories = async (): Promise<Subcategory[]> => {
  const response = await api.get('/subcategories/all');
  return response.data;
};

export const getSubcategoryById = async (id: string): Promise<Subcategory> => {
  const response = await api.get(`/subcategories/${id}`);
  return response.data;
};

export const createSubcategory = async (data: CreateSubcategoryRequest): Promise<Subcategory> => {
  const response = await api.post<Subcategory>('/subcategories/create', data);
  return response.data;
};

export const updateSubcategory = async (id: string, data: CreateSubcategoryRequest): Promise<Subcategory> => {
  const response = await api.patch<Subcategory>(`/subcategories/${id}`, data);
  return response.data;
};