import { useQuery } from '@tanstack/react-query';
import { getLessonById } from '../../services/api/lessons';
import { Lesson } from '../../types/courses/lesson';

export const useLessonDetails = (lessonId: string | null) => {
  return useQuery<Lesson>({
    queryKey: ['lesson', lessonId],
    queryFn: () => getLessonById(lessonId!),
    enabled: !!lessonId
  });
};

export const useLessonsDetails = (lessonIds: string[], enabled: boolean) => {
  return useQuery<Lesson[]>({
    queryKey: ['lessons', lessonIds],
    queryFn: async () => {
      const lessons = await Promise.all(
        lessonIds.map(id => getLessonById(id))
      );
      return lessons;
    },
    enabled: enabled && lessonIds.length > 0
  });
};