import { useQuery } from '@tanstack/react-query';
import { getSubcategoryById } from '../services/api';
import { Subcategory } from '../types/catalog';

export const useSubcategoryDetails = (subcategoryId: string) => {
  return useQuery<Subcategory>({
    queryKey: ['subcategory', subcategoryId],
    queryFn: () => getSubcategoryById(subcategoryId),
    enabled: !!subcategoryId,
  });
};