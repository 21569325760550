import React, { useState } from 'react';
import { Card, Typography, Button, Space, Tooltip, message, Empty } from 'antd';
import { EditOutlined, CheckCircleOutlined, WarningOutlined, DownOutlined, UpOutlined, RightOutlined } from '@ant-design/icons';
import { Module } from '../../../types/courses/module';
import { Lesson } from '../../../types/courses/lesson';
import { updateLesson } from '../../../services/api/lessons';
import ModuleEdit from '../modules/ModuleEdit';
import LessonEdit from '../lessons/LessonEdit';
import ModuleSearch from '../modules/ModuleSearch';
import CreateModule from '../modules/CreateModule';
import styles from './LessonsSection.module.css';
import { useAddModuleToCourse } from '../../../hooks/courses/useAddModuleToCourse';
import { Course } from '../../../types/courses/course';

const { Text, Title } = Typography;

interface LessonsSectionProps {
  courseId: string;
  modules: Module[];
  lessons: Lesson[];
  course: Course;
  onModuleUpdate: (moduleId: string, values: Partial<Module>) => Promise<void>;
  onLessonUpdate: (updatedLesson: Lesson) => void;
  selectedModuleId?: string | null;
  selectedLessonId?: string | null;
  onModuleSelect: (moduleId: string | null) => void;
  onLessonSelect: (lessonId: string | null) => void;
  allModules?: Module[];
  onModulesUpdate?: (updatedModules: Module[]) => void;
}

const LessonsSection: React.FC<LessonsSectionProps> = ({ 
  courseId,
  modules = [],
  lessons,
  course,
  onModuleUpdate,
  onLessonUpdate,
  selectedModuleId,
  selectedLessonId,
  onModuleSelect,
  onLessonSelect,
  onModulesUpdate
}) => {
  const [expandedModules, setExpandedModules] = useState<string[]>([]);
  const [isEdited, setIsEdited] = useState(false);
  const [editKey, setEditKey] = useState(0);

  const selectedModule = selectedModuleId 
    ? modules.find(m => m.id === selectedModuleId)
    : null;

  const selectedLesson = selectedLessonId
    ? lessons.find(l => l.id === selectedLessonId)
    : null;

  const addModuleMutation = useAddModuleToCourse(courseId, course);

  const handleModuleEdit = (moduleId: string) => {
    if (isEdited) {
      message.warning('Сохраните изменения перед переходом');
      return;
    }
    onModuleSelect(moduleId);
    onLessonSelect(null);
    setEditKey(prev => prev + 1);
    setIsEdited(false);
  };

  const handleLessonEdit = (lessonId: string) => {
    if (isEdited) {
      message.warning('Сохраните изменения перед переходом');
      return;
    }
    onLessonSelect(lessonId);
    onModuleSelect(null);
    setEditKey(prev => prev + 1);
    setIsEdited(false);
  };

  const handleModuleSave = async (moduleId: string, values: Partial<Module>) => {
    try {
      await onModuleUpdate(moduleId, values);
      const updatedModule = {
        ...selectedModule!,
        ...values
      };
      const updatedModules = modules.map(module =>
        module.id === moduleId ? updatedModule : module
      );
      if (onModulesUpdate) {
        onModulesUpdate(updatedModules);
      }
      setIsEdited(false);
      message.success('Модуль успешно обновлен');
      handleClose();
    } catch (error) {
      message.error('Ошибка при обновлении модуля');
    }
  };

  const handleLessonSave = async (lessonId: string, values: Partial<Lesson>) => {
    try {
      const updatedLesson = await updateLesson(lessonId, values);
      message.success('Урок успешно обновлен');
      onLessonUpdate(updatedLesson);
      setIsEdited(false);
    } catch (error) {
      message.error('Ошибка при обновлении урока');
    }
  };

  const handleClose = () => {
    onModuleSelect(null);
    onLessonSelect(null);
    setIsEdited(false);
  };

  const handleFormChange = (changed: boolean) => {
    setIsEdited(changed);
  };

  const getLessonStatus = (lesson: Lesson) => {
    const hasDescription = !!lesson.description;
    const hasLinkVideo = !!lesson.link_video;
    const hasPreviewVideo = !!lesson.preview_video;
    const isPublished = lesson.status === 'published';
    const isFullyFilled = hasDescription && hasLinkVideo && hasPreviewVideo;
    
    if (isPublished && isFullyFilled) {
      return <CheckCircleOutlined className={styles.iconComplete} />;
    }
    return <WarningOutlined className={styles.iconWarning} />;
  };

  const toggleModule = (moduleId: string) => {
    setExpandedModules(prev => 
      prev.includes(moduleId) 
        ? prev.filter(id => id !== moduleId)
        : [...prev, moduleId]
    );
  };

  const getLessonStatusTooltip = (lesson: Lesson) => {
    const hasDescription = !!lesson.description;
    const hasLinkVideo = !!lesson.link_video;
    const hasPreviewVideo = !!lesson.preview_video;
    const isPublished = lesson.status === 'published';
    const isFullyFilled = hasDescription && hasLinkVideo && hasPreviewVideo;
    
    if (isPublished && isFullyFilled) {
      return 'Урок опубликован';
    }
  
    const issues = [];
    if (!isFullyFilled) issues.push('не заполнен');
    if (!isPublished) issues.push('не опубликован');
    
    return `Урок ${issues.join(' и ')}`;
  };

  const handleLessonCreate = (newLesson: Lesson) => {
    onLessonUpdate(newLesson);
  };

  const handleModuleAdd = async (module: Module) => {
    try {
      await addModuleMutation.mutateAsync(module);
      message.success('Модуль успешно добавлен в курс');
      onModuleSelect(null);
    } catch (error) {
      message.error('Не удалось добавить модуль в курс');
    }
  };

  React.useEffect(() => {
    if (selectedModule && !isEdited) {
      const updatedModules = modules.map(module =>
        module.id === selectedModule.id ? selectedModule : module
      );
      if (onModulesUpdate) {
        onModulesUpdate(updatedModules);
      }
      setExpandedModules(prev => [...prev]);
    }
  }, [selectedModule, modules, isEdited, onModulesUpdate]);

  return (
    <div className={styles.container}>
      <div className={styles.modulesList}>
        {modules.length > 0 ? (
          <>
            {modules.map((module, index) => (
              <Card 
                key={module.id} 
                className={`${styles.moduleCard} ${selectedModuleId === module.id ? styles.active : ''}`}
              >
                <div className={styles.moduleContent}>
                  <div className={styles.moduleHeader}>
                    <Space direction="vertical" size={4} style={{ flex: 1 }}>
                      <Text type="secondary">Модуль {index + 1}</Text>
                      <Title level={5} style={{ margin: 0 }}>{module.title}</Title>
                    </Space>
                    <Button 
                      type="text" 
                      icon={<EditOutlined />}
                      onClick={() => handleModuleEdit(module.id)}
                    />
                  </div>
  
                  {expandedModules.includes(module.id) && (
                    <div className={styles.lessonsList}>
                      {module.lessons?.map(lessonRef => {
                        const lesson = lessons.find(l => l.id === lessonRef.id);
                        if (!lesson) return null;
                        
                        return (
                          <div 
                            key={lesson.id}
                            className={`${styles.lessonItem} ${selectedLessonId === lesson.id ? styles.active : ''}`}
                            onClick={() => handleLessonEdit(lesson.id)}
                          >
                            <Text>{lesson.title}</Text>
                            <Space size="middle">
                              <Tooltip title={getLessonStatusTooltip(lesson)}>
                                {getLessonStatus(lesson)}
                              </Tooltip>
                              <RightOutlined className={styles.arrowIcon} />
                            </Space>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
  
                <Button
                  type="text"
                  className={styles.toggleButton}
                  onClick={() => toggleModule(module.id)}
                  icon={expandedModules.includes(module.id) ? <UpOutlined /> : <DownOutlined />}
                >
                  {expandedModules.includes(module.id) ? 'Скрыть уроки' : 'Показать уроки'}
                </Button>
              </Card>
            ))}
          </>
        ) : (
          <div className={styles.emptyModules}>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span>
                  В курсе пока нет модулей
                </span>
              }
            />
          </div>
        )}
        <Button 
          type="dashed" 
          className={styles.addModuleButton}
          onClick={() => onModuleSelect('search')}
        >
          Добавить модуль
        </Button>
      </div>
      <div className={styles.detailsPanel}>
        {selectedModuleId === 'search' ? (
          <ModuleSearch
            existingModuleIds={modules.map(module => module.id)}
            onClose={() => onModuleSelect(null)}
            lessons={lessons}
            onModuleSelect={(selectedModule) => {
              if (selectedModule === 'create') {
                onModuleSelect('create');
              } else {
                handleModuleAdd(selectedModule);
              }
            }}
          />
        ) : selectedModuleId === 'create' ? (
          <CreateModule
            onClose={() => onModuleSelect(null)}
            onSuccess={() => {
              onModuleSelect(null);
            }}
          />
        ) : selectedModule ? (
          <ModuleEdit
            key={`module-${selectedModule.id}-${editKey}`}
            module={selectedModule}
            courseId={courseId}
            onClose={handleClose}
            onSave={(values) => handleModuleSave(selectedModule.id, values)}
            onFormChange={handleFormChange}
            onLessonCreate={handleLessonCreate}
            onModuleUpdate={(updatedModule) => {
              const updatedModules = modules.map(m => 
                m.id === updatedModule.id ? updatedModule : m
              );
              if (onModulesUpdate) {
                onModulesUpdate(updatedModules);
              }
            }}
          />
        ) : selectedLesson ? (
          <LessonEdit
            key={`lesson-${selectedLesson.id}-${editKey}`}
            lesson={selectedLesson}
            onClose={handleClose}
            onSave={(values) => handleLessonSave(selectedLesson.id, values)}
            onFormChange={handleFormChange}
          />
        ) : (
          <div className={styles.emptyState}>
            <div className={styles.emptyStateContent}>
              <Text type="secondary">
                Выберите модуль или урок
                <br />
                для редактирования
              </Text>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LessonsSection;