import React from 'react';
import { Input, List, Spin, Empty } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { Module } from '../../../types/courses/module';
import { useAllModules } from '../../../hooks/modules/useAllModules';
import ModuleCard from './ModuleCard';
import BaseModal from '../../common/Modal/BaseModal';
import styles from './ModuleSearch.module.css';
import { Lesson } from '../../../types/courses/lesson';

interface ModuleSearchProps {
  existingModuleIds: string[];
  onClose: () => void;
  onModuleSelect?: (module: Module | 'create') => void;
  lessons: Lesson[];
}

const ModuleSearch: React.FC<ModuleSearchProps> = ({
  existingModuleIds,
  onClose,
  onModuleSelect,
  lessons
}) => {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [expandedModuleId, setExpandedModuleId] = React.useState<string | null>(null);
  const { data: allModules = [], isLoading } = useAllModules();
  
  // Фильтруем модули, исключая уже добавленные в курс
  const availableModules = React.useMemo(() => 
    allModules.filter(module => !existingModuleIds.includes(module.id)),
    [allModules, existingModuleIds]
  );

  // Фильтруем модули по поисковому запросу
  const filteredModules = React.useMemo(() => {
    if (searchQuery.length < 3) {
      return availableModules;
    }
    
    const query = searchQuery.toLowerCase();
    return availableModules.filter(module => 
      module.title?.toLowerCase().includes(query) || false
    );
  }, [availableModules, searchQuery]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleModuleClick = (module: Module) => {
    setExpandedModuleId(expandedModuleId === module.id ? null : module.id);
  };

  const handleModuleAdd = (module: Module) => {
    onModuleSelect?.(module);
  };

  const handleCreateClick = () => {
    onModuleSelect?.('create');
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className={styles.spinnerContainer}>
          <Spin size="large" />
        </div>
      );
    }

    if (!availableModules.length) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Нет доступных модулей для добавления"
        />
      );
    }

    if (searchQuery.length >= 3 && !filteredModules.length) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Ничего не найдено"
        />
      );
    }

    return (
      <List
        dataSource={filteredModules}
        renderItem={(module) => (
          <ModuleCard
            key={module.id}
            module={module}
            onClick={() => handleModuleClick(module)}
            isExpanded={expandedModuleId === module.id}
            onAdd={() => handleModuleAdd(module)}
            lessons={lessons}
          />
        )}
      />
    );
  };

  return (
    <BaseModal
      title="Поиск модулей"
      onClose={onClose}
      primaryButton={{
        text: 'Создать модуль',
        type: 'primary',
        icon: <PlusOutlined />,
        onClick: handleCreateClick
      }}
      secondaryButton={{
        text: 'Закрыть',
        onClick: onClose
      }}
    >
      <div className={styles.searchContainer}>
        <Input
          placeholder="Поиск модулей"
          value={searchQuery}
          onChange={handleSearch}
          prefix={<SearchOutlined />}
          allowClear
        />
        <div className={styles.modulesList}>
          {renderContent()}
        </div>
      </div>
    </BaseModal>
  );
};

export default ModuleSearch;