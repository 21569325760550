import React from 'react';
import { Typography } from 'antd';
import commonStyles from '../../styles/CommonPage.module.css';

const { Title } = Typography;

const DashboardPage: React.FC = () => {
  return (
    <div className={commonStyles.pageContainer}>
      <Title level={2} className={commonStyles.pageTitle}>
        Дашборд
      </Title>
      <div className={commonStyles.pageContent}>
        <p>Здесь будет отображаться основная статистика</p>
      </div>
    </div>
  );
};

export default DashboardPage;