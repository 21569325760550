import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { CollapseButtonProps } from './types';
import styles from './styles.module.css';
import classNames from 'classnames';

export const CollapseButton: React.FC<CollapseButtonProps> = ({
  isOutside,
  collapsed,
  siderWidth,
  onCollapse,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isOutside) {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 50);
      return () => clearTimeout(timer);
    }
    setIsVisible(false);
  }, [isOutside]);

  return (
    <Button
      type="text"
      icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
      onClick={onCollapse}
      className={classNames(
        styles.collapseButton,
        isOutside ? styles.collapseButtonOutside : styles.collapseButtonInside,
        isOutside && isVisible && styles.collapseButtonOutsideVisible
      )}
      style={{
        ...(isOutside ? { 
          left: siderWidth - 1, 
          top: 16,
          padding: 0,
          minWidth: 'auto',
        } : {}),
      }}
    />
  );
};