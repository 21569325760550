import React from 'react';
import { Card, Skeleton } from 'antd';
import { useSubcategoryDetails } from '../../../hooks/useSubcategoryDetails';

interface SubcategoryCardProps {
  subcategoryId: string;
}

const SubcategoryCard: React.FC<SubcategoryCardProps> = ({ subcategoryId }) => {
  const { data: subcategory, isLoading } = useSubcategoryDetails(subcategoryId);

  if (isLoading) {
    return <Skeleton active />;
  }

  if (!subcategory) {
    return null;
  }

  return (
    <Card 
      size="small" 
      style={{ 
        marginBottom: 4,
        borderRadius: 8,
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <strong>{subcategory.title}</strong>
        <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
          Курсов: {subcategory.courses?.length || 0}
        </span>
      </div>
    </Card>
  );
};

export default SubcategoryCard;