import React from 'react';
import { 
  DashboardOutlined, 
  UserOutlined, 
  BookOutlined,
  AppstoreOutlined,
  ReadOutlined
} from '@ant-design/icons';
import { MenuProps } from 'antd';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: string,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

export const menuItems: MenuItem[] = [
  getItem('Дашборд', 'dashboard', <DashboardOutlined />),
  getItem('Каталог', 'catalog', <BookOutlined />, [
    getItem('Категории', 'catalog/categories', <AppstoreOutlined />),
    getItem('Курсы', 'catalog/courses', <ReadOutlined />),
  ]),
  getItem('Пользователи', 'users', <UserOutlined />),
];