import React, { ReactNode } from 'react';
import { Typography, Button, Space, FormInstance } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import styles from './BaseModal.module.css';

const { Title } = Typography;

interface ModalButton {
  text: string;
  type?: 'primary' | 'default' | 'text' | 'link' | 'dashed';
  icon?: ReactNode;
  onClick: () => void;
  isDisabled?: boolean;
}

interface BaseModalProps {
  title: string;
  children: ReactNode;
  showCloseIcon?: boolean;
  primaryButton?: ModalButton;
  secondaryButton?: ModalButton;
  onClose: () => void;
  form?: FormInstance;
  isFormChanged?: boolean;
}

const BaseModal: React.FC<BaseModalProps> = ({
  title,
  children,
  showCloseIcon = false,
  primaryButton,
  secondaryButton,
  onClose,
  form,
  isFormChanged
}) => {

  const handlePrimaryClick = () => {
    if (form && primaryButton) {
      form.submit();
    } else if (primaryButton) {
      primaryButton.onClick();
    }
  };

  const isButtonDisabled = React.useMemo(() => {
    if (primaryButton?.isDisabled !== undefined) {
      return primaryButton.isDisabled;
    }
    return form && !isFormChanged;
  }, [primaryButton?.isDisabled, form, isFormChanged]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Title level={4}>{title}</Title>
        {showCloseIcon && (
          <Button 
            type="text" 
            icon={<CloseOutlined />} 
            onClick={onClose}
            className={styles.closeButton}
          />
        )}
      </div>

      <div className={styles.content}>
        {children}
      </div>

      {(primaryButton || secondaryButton) && (
        <div className={styles.footer}>
          <Space>
            {secondaryButton && (
              <Button
                type={secondaryButton.type || 'default'}
                onClick={secondaryButton.onClick}
                icon={secondaryButton.icon}
                disabled={secondaryButton.isDisabled}
              >
                {secondaryButton.text}
              </Button>
            )}
            {primaryButton && (
              <Button
                type={primaryButton.type || 'primary'}
                onClick={handlePrimaryClick}
                icon={primaryButton.icon}
                disabled={isButtonDisabled}
              >
                {primaryButton.text}
              </Button>
            )}
          </Space>
        </div>
      )}
    </div>
  );
};

export default BaseModal;