import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { createSubcategory, getAllSubcategories, updateSubcategory } from '../../services/api';
import { Subcategory, CreateSubcategoryRequest } from '../../types/catalog';

export const useSubcategories = () => {
  const queryClient = useQueryClient();

  const { data: subcategories = [] } = useQuery<Subcategory[]>({
    queryKey: ['subcategories'],
    queryFn: getAllSubcategories,
  });

  const createMutation = useMutation({
    mutationFn: createSubcategory,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['subcategories'] });
      message.success('Подкатегория создана');
    },
    onError: (error: any) => {
      message.error('Ошибка при создании подкатегории');
      return error;
    }
  });

  const updateMutation = useMutation({
    mutationFn: ({ id, data }: { id: string; data: CreateSubcategoryRequest }) => 
      updateSubcategory(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['subcategories'] });
      message.success('Подкатегория обновлена');
    },
    onError: (error: any) => {
      message.error('Ошибка при обновлении подкатегории');
      return error;
    }
  });

  return {
    subcategories,
    createSubcategory: createMutation.mutateAsync,
    updateSubcategory: updateMutation.mutateAsync,
    isLoading: createMutation.isPending || updateMutation.isPending
  };
};