import React from 'react';
import { Form, Input, InputNumber } from 'antd';
import { UpdateCourseRequest } from '../../../types/courses/course';
import { CourseBase } from '../../../types/courses/course';
import FileUpload from '../../Files/FileUpload';
import styles from './GeneralSection.module.css';

const { TextArea } = Input;

interface GeneralSectionProps {
  course: CourseBase;
  onSave: (values: UpdateCourseRequest) => void;
  formRef: React.MutableRefObject<{ submit: () => void } | undefined>;
}

const GeneralSection: React.FC<GeneralSectionProps> = ({ course, onSave, formRef }) => {
  const [form] = Form.useForm();

  React.useEffect(() => {
    formRef.current = {
      submit: () => form.submit()
    };
  }, [form, formRef]);

  return (
    <div className={styles.container}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          title: course.title,
          description: course.description,
          image: course.image,
          price: course.price,
        }}
        onFinish={onSave}
      >
        <Form.Item
          label="Название курса"
          name="title"
          rules={[{ required: true, message: 'Введите название курса' }]}
        >
          <Input placeholder="Введите название курса" />
        </Form.Item>

        <Form.Item
          label="Описание"
          name="description"
          rules={[{ required: true, message: 'Введите описание курса' }]}
        >
          <TextArea 
            rows={4} 
            placeholder="Введите описание курса"
          />
        </Form.Item>

        <Form.Item
          label="Изображение"
          name="image"
          rules={[{ required: true, message: 'Добавьте изображение курса' }]}
        >
          <FileUpload purpose="course_image" />
        </Form.Item>

        <Form.Item
          label="Стоимость"
          name="price"
          rules={[{ required: true, message: 'Укажите стоимость курса' }]}
        >
          <InputNumber 
            min={0}
            placeholder="Укажите стоимость курса"
            addonAfter="₽"
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default GeneralSection;