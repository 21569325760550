import React from 'react';
import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';
import { UserProfile } from '../UserProfile/UserProfile';
import { CollapseButton } from './CollapseButton';
import { SidebarMenu } from './SidebarMenu';
import { LogoutButton } from './LogoutButton';
import { SidebarProps } from './types';
import styles from './styles.module.css';
import classNames from 'classnames';

const { Sider } = Layout;

export const Sidebar: React.FC<SidebarProps> = ({
  collapsed,
  siderWidth,
  onCollapse,
}) => {
  const location = useLocation();
  const isProfilePage = location.pathname === '/profile';

  return (
    <>
      <Sider 
        collapsed={collapsed}
        collapsible={false}
        width={siderWidth}
        className={styles.siderContainer}
      >
        <div className={styles.innerContainer}>
          <div className={classNames(styles.header, {
            [styles.headerActive]: isProfilePage
          })}>
            <UserProfile collapsed={collapsed} />
            {!collapsed && (
              <CollapseButton
                isOutside={false}
                collapsed={collapsed}
                siderWidth={siderWidth}
                onCollapse={() => onCollapse(!collapsed)}
              />
            )}
          </div>

          <SidebarMenu />
          <LogoutButton collapsed={collapsed} />
        </div>
      </Sider>

      {collapsed && (
        <CollapseButton
          isOutside={true}
          collapsed={collapsed}
          siderWidth={siderWidth}
          onCollapse={() => onCollapse(!collapsed)}
        />
      )}
    </>
  );
};