import React from 'react';
import { Table, Tag } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { CategoryTableItem } from '../../../types/catalog';
import styles from '../common/Table.module.css';

interface CategoryTableProps {
    data: CategoryTableItem[];
    onRowClick: (id: string) => void;
}

const CategoryTable: React.FC<CategoryTableProps> = ({ data, onRowClick }) => {
    const columns: ColumnsType<CategoryTableItem> = [
        {
            title: 'Название',
            dataIndex: 'title',
            key: 'title',
            width: '40%',
        },
        {
            title: 'Позиция',
            dataIndex: 'position',
            key: 'position',
            width: '20%',
            sorter: (a, b) => a.position - b.position,
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Подкатегории',
            dataIndex: 'subcategoriesCount',
            key: 'subcategoriesCount',
            width: '20%',
            render: (count: number) => (
                <Tag color="blue">{count}</Tag>
            ),
        },
        {
            title: 'Изображение',
            dataIndex: 'hasImage',
            key: 'hasImage',
            width: '20%',
            align: 'center',
            render: (hasImage: boolean) => (
                hasImage ? 
                    <CheckOutlined style={{ color: '#52c41a' }} /> : 
                    <CloseOutlined style={{ color: '#ff4d4f' }} />
            ),
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            onRow={(record) => ({
                onClick: () => onRowClick(record.key),
                className: styles.tableRow,
            })}
        />
    );
};

export default CategoryTable;