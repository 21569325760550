import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateLesson } from '../../services/api/lessons';
import { Lesson } from '../../types/courses/lesson';

export const useLessonUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ lessonId, values }: { lessonId: string; values: Partial<Lesson> }) => 
      updateLesson(lessonId, values),
    onSuccess: (updatedLesson) => {
      // Обновляем кэш урока
      queryClient.setQueryData(['lesson', updatedLesson.id], updatedLesson);

      // Обновляем кэш списка уроков
      queryClient.setQueryData(['lessons'], (oldLessons: Lesson[] | undefined) => {
        if (!oldLessons) return oldLessons;
        return oldLessons.map(lesson =>
          lesson.id === updatedLesson.id ? updatedLesson : lesson
        );
      });

      // Инвалидируем связанные запросы
      queryClient.invalidateQueries({ queryKey: ['lessons'] });
    },
  });
}; 