import React from 'react';
import { Card, Descriptions, Skeleton, Avatar } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { getUserProfile } from '../../services/api';
import { UserOutlined } from '@ant-design/icons';
import styles from './ProfilePage.module.css';
import commonStyles from '../../styles/CommonPage.module.css';

const ProfilePage: React.FC = () => {
  const { data: user, isLoading } = useQuery({
    queryKey: ['userProfile'],
    queryFn: getUserProfile,
    staleTime: 5 * 60 * 1000,
  });

  if (isLoading) {
    return (
      <div className={commonStyles.pageContainer}>
        <Skeleton active />
      </div>
    );
  }

  return (
    <div className={commonStyles.pageContainer}>
      <Card 
        title="Профиль администратора" 
        className={styles.profileCard}
        bordered={false}
      >
        <div className={styles.profileHeader}>
          <Avatar 
            size={100}
            icon={<UserOutlined />} 
            className={styles.profileImage}
          />
          <div className={styles.profileInfo}>
            <h2 className={styles.profileName}>
              {user?.first_name} {user?.last_name}
            </h2>
            <p className={styles.profileUsername}>
              @{user?.username || 'нет имени пользователя'}
            </p>
          </div>
        </div>

        <Descriptions 
          bordered 
          column={1}
          className={styles.profileDetails}
        >
          <Descriptions.Item label="Имя">{user?.first_name || '—'}</Descriptions.Item>
          <Descriptions.Item label="Фамилия">{user?.last_name || '—'}</Descriptions.Item>
          <Descriptions.Item label="Имя пользователя">{user?.username || '—'}</Descriptions.Item>
          <Descriptions.Item label="Telegram ID">{user?.telegram_id || '—'}</Descriptions.Item>
          <Descriptions.Item label="Язык">{user?.language || '—'}</Descriptions.Item>
          <Descriptions.Item label="Валюта">{user?.currency || '—'}</Descriptions.Item>
        </Descriptions>
      </Card>
    </div>
  );
};

export default ProfilePage;