import React, { useEffect, useCallback } from 'react';
import { Card, Typography, message } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import styles from './LoginPage.module.css';

const { Title } = Typography;

interface TelegramUser {
  [key: string]: any;
}

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();
  const telegramRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const from = (location.state as any)?.from?.pathname || '/dashboard';
      navigate(from, { replace: true });
    }
  }, [navigate, location]);

  const onTelegramAuth = useCallback(async (user: TelegramUser) => {
    console.log('Raw Telegram data:', user);
    
    try {
      const initData = Object.entries(user)
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      console.log('Sending init data:', initData);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/widget`,
        {
          method: 'GET',
          headers: {
            'X-Telegram-Init-Data': initData
          },
          credentials: 'include',
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.detail || 'Ошибка авторизации');
      }

      const data = await response.json();
      login(data.access_token);
      
      message.success('Вы авторизованы');
      navigate('/dashboard');
    } catch (error) {
      console.error('Auth error:', error);
      message.error(error instanceof Error ? error.message : 'Ошибка авторизации');
    }
  }, [login, navigate]);

  useEffect(() => {
    if (!process.env.REACT_APP_TELEGRAM_BOT_ID || ! "smltlkBot" ) {
      return;
    }

    const currentRef = telegramRef.current;
    if (currentRef) {
      (window as any).onTelegramAuth = onTelegramAuth;

      const script = document.createElement('script');
      script.src = "https://telegram.org/js/telegram-widget.js?22";
      script.async = true;
      script.setAttribute('data-telegram-login', process.env.REACT_APP_TELEGRAM_BOT_ID || "smltlkBot");
      script.setAttribute('data-size', 'large');
      script.setAttribute('data-radius', '10');
      script.setAttribute('data-onauth', 'onTelegramAuth(user)');
      script.setAttribute('data-request-access', 'write');

      currentRef.appendChild(script);
    }

    return () => {
      if (currentRef) {
        currentRef.innerHTML = '';
      }
      delete (window as any).onTelegramAuth;
    };
  }, [onTelegramAuth]);

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <Title level={2} className={styles.title}>
          Вход в админ-панель
        </Title>
        <div ref={telegramRef} className={styles.telegramButton} />
      </Card>
    </div>
  );
};

export default LoginPage;