import React from 'react';
import { Input, Form, List, Divider, message, Spin } from 'antd';
import { Lesson } from '../../../types/courses/lesson';
import { useLessons } from '../../../hooks/lessons/useLessons';
import { createModule } from '../../../services/api/modules';
import { CreateModuleRequest } from '../../../types/courses/module';
import LessonCard from './ModuleLessonCard';
import BaseModal from '../../common/Modal/BaseModal';

interface CreateModuleProps {
  onClose: () => void;
  onSuccess: () => void;
}

const CreateModule: React.FC<CreateModuleProps> = ({ onClose, onSuccess }) => {
  const [form] = Form.useForm();
  const { data: lessons = [], isLoading } = useLessons(true);
  const [isFormValid, setIsFormValid] = React.useState(false);
  
  const watchedLessonIds = Form.useWatch('lessonIds', form);
  const selectedLessonIds = React.useMemo(() => 
    watchedLessonIds || [], 
    [watchedLessonIds]
  );

  const handleLessonClick = (lessonId: string) => {
    const currentLessonIds = form.getFieldValue('lessonIds') || [];
    const newLessonIds = currentLessonIds.includes(lessonId)
      ? currentLessonIds.filter((id: string) => id !== lessonId)
      : [...currentLessonIds, lessonId];
    
    form.setFieldValue('lessonIds', newLessonIds);
    handleValuesChange();
  };

  const handleValuesChange = async () => {
    try {
      const values = form.getFieldsValue();
      const filledFields = Object.keys(values).filter(key => {
        const value = values[key];
        return Array.isArray(value) ? value.length > 0 : !!value;
      });

      if (filledFields.length > 0) {
        await form.validateFields(filledFields);
      }
      
      const title = form.getFieldValue('title');
      const lessonIds = form.getFieldValue('lessonIds');
      setIsFormValid(!!title && lessonIds?.length > 0);
    } catch {
      setIsFormValid(false);
    }
  };

  const handleSubmit = async (values: { title: string; lessonIds: string[] }) => {
    try {
      const moduleData: CreateModuleRequest = {
        title: values.title,
        lessons: values.lessonIds.map((id, index) => ({
          id: id,
          position: index + 1,
          free: false
        }))
      };
      await createModule(moduleData);
      message.success('Модуль создан и добавлен в курс');
      onSuccess();
    } catch (error) {
      message.error('Не получилось создать модуль');
    }
  };

  const sortedLessons = React.useMemo(() => [
    ...selectedLessonIds.map((id: string) => lessons.find((l: Lesson) => l.id === id)).filter(Boolean),
    ...lessons.filter((lesson: Lesson) => !selectedLessonIds.includes(lesson.id))
  ], [selectedLessonIds, lessons]);

  return (
    <BaseModal
      title="Создание модуля"
      onClose={onClose}
      form={form}
      primaryButton={{
        text: 'Создать',
        type: 'primary',
        onClick: () => form.submit(),
        isDisabled: !isFormValid
      }}
      secondaryButton={{
        text: 'Отмена',
        onClick: onClose
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ title: '', lessonIds: [] }}
        onFinish={handleSubmit}
        onValuesChange={handleValuesChange}
        validateTrigger={['onSubmit']}
      >
        <Form.Item
          name="title"
          label="Название модуля"
          rules={[{ required: true, message: 'Введите название модуля' }]}
          validateTrigger={['onBlur']}
        >
          <Input placeholder="Введите название модуля" />
        </Form.Item>

        <Form.Item
          name="lessonIds"
          label="Уроки"
          rules={[{ required: true, message: 'Выберите хотя бы один урок' }]}
          validateTrigger={['onChange']}
        >
          {isLoading ? (
            <Spin size="large" />
          ) : (
            <List
              dataSource={sortedLessons}
              split={false}
              renderItem={(lesson: Lesson, index: number) => (
                <>
                  {index === selectedLessonIds.length && selectedLessonIds.length > 0 && (
                    <Divider>Все уроки</Divider>
                  )}
                  <LessonCard
                    key={lesson.id}
                    lesson={lesson}
                    selected={selectedLessonIds.includes(lesson.id)}
                    onClick={() => handleLessonClick(lesson.id)}
                  />
                </>
              )}
            />
          )}
        </Form.Item>
      </Form>
    </BaseModal>
  );
};

export default CreateModule;