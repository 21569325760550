import React from 'react';
import { Modal, Form, Input, Button, Space, Select } from 'antd';
import { createLesson } from '../../../services/api/lessons';
import { CreateLessonRequest, Lesson } from '../../../types/courses/lesson';
import styles from './CreateLesson.module.css';

interface CreateLessonProps {
  visible: boolean;
  onCancel: () => void;
  onCreate: (lesson: Lesson) => void;
}

const CreateLesson: React.FC<CreateLessonProps> = ({
  visible,
  onCancel,
  onCreate
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values: CreateLessonRequest) => {
    try {
      setLoading(true);
      const newLesson = await createLesson({
        ...values,
        lesson_items: []
      });
      onCreate(newLesson);
      form.resetFields();
    } catch (error) {
      console.error('Error creating lesson:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Создание урока"
      open={visible}
      onCancel={onCancel}
      footer={null}
      maskClosable={false}
      width={600}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          status: 'draft'
        }}
      >
        <Form.Item
          name="title"
          label="Название урока"
          rules={[{ required: true, message: 'Введите название урока' }]}
        >
          <Input placeholder="Введите название урока" />
        </Form.Item>

        <Form.Item
          name="description"
          label="Описание"
        >
          <Input.TextArea 
            placeholder="Введите описание урока"
            rows={4}
          />
        </Form.Item>

        <Form.Item
          name="status"
          label="Статус"
          rules={[{ required: true, message: 'Выберите статус урока' }]}
        >
          <Select>
            <Select.Option value="draft">Черновик</Select.Option>
            <Select.Option value="published">Опубликован</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="link_video"
          label="Ссылка на видео"
          rules={[
            { 
              type: 'url', 
              message: 'Введите корректную ссылку' 
            }
          ]}
        >
          <Input placeholder="https://..." />
        </Form.Item>

        <Form.Item
          name="preview_video"
          label="Ссылка на превью"
          rules={[
            { 
              type: 'url', 
              message: 'Введите корректную ссылку' 
            }
          ]}
        >
          <Input placeholder="https://..." />
        </Form.Item>

        <div className={styles.footer}>
          <Space>
            <Button onClick={onCancel}>
              Отмена
            </Button>
            <Button 
              type="primary" 
              htmlType="submit"
              loading={loading}
            >
              Создать
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateLesson;