import React from 'react';
import { Form, Input, Select, message } from 'antd';
import { Lesson } from '../../../types/courses/lesson';
import { useLessonUpdate } from '../../../hooks/lessons/useLessonUpdate';
import BaseModal from '../../common/Modal/BaseModal';

const { TextArea } = Input;

interface LessonEditProps {
  lesson: Lesson;
  onClose: () => void;
  onSave: (values: Partial<Lesson>) => Promise<void>;
  onFormChange: (changed: boolean) => void;
}

const LessonEdit: React.FC<LessonEditProps> = ({ 
  lesson, 
  onClose, 
  onSave, 
  onFormChange 
}) => {
  const [form] = Form.useForm();
  const [isFormChanged, setIsFormChanged] = React.useState(false);
  const [currentLesson, setCurrentLesson] = React.useState(lesson);

  const lessonUpdate = useLessonUpdate();

  React.useEffect(() => {
    setCurrentLesson(lesson);
    form.setFieldsValue(lesson);
    setIsFormChanged(false);
  }, [lesson, form]);

  const handleValuesChange = () => {
    const formValues = form.getFieldsValue();
    const hasChanges = Object.keys(formValues).some(
      key => formValues[key] !== currentLesson[key as keyof Lesson]
    );
    setIsFormChanged(hasChanges);
    onFormChange(hasChanges);
  };

  const handleSubmit = async (values: Partial<Lesson>) => {
    try {
      await lessonUpdate.mutateAsync({
        lessonId: lesson.id,
        values
      });

      message.success('Изменения сохранены');

      const updatedLesson = {
        ...currentLesson,
        ...values
      };
      
      setCurrentLesson(updatedLesson);
      form.setFieldsValue(updatedLesson);
      setIsFormChanged(false);
      onFormChange(false);
      
    } catch (error) {
      message.error('Не удалось сохранить изменения');
    }
  };

  return (
    <BaseModal
      title="Редактирование урока"
      onClose={onClose}
      form={form}
      isFormChanged={isFormChanged}
      primaryButton={{
        text: 'Сохранить',
        type: 'primary',
        onClick: () => form.submit()
      }}
      secondaryButton={{
        text: 'Отмена',
        onClick: onClose
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={lesson}
        onValuesChange={handleValuesChange}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="title"
          label="Название урока"
          rules={[{ required: true, message: 'Введите название урока' }]}
        >
          <Input placeholder="Введите название урока" />
        </Form.Item>

        <Form.Item
          name="description"
          label="Описание"
        >
          <TextArea 
            rows={4} 
            placeholder="Введите описание урока"
          />
        </Form.Item>

        <Form.Item
          name="status"
          label="Статус"
          rules={[{ required: true, message: 'Выберите статус урока' }]}
        >
          <Select>
            <Select.Option value="published">Опубликован</Select.Option>
            <Select.Option value="unpublished">Не опубликован</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="link_video"
          label="Ссылка на видео"
        >
          <Input placeholder="Введите ссылку на видео" />
        </Form.Item>

        <Form.Item
          name="preview_video"
          label="Ссылка на превью"
        >
          <Input placeholder="Введите ссылку на превью" />
        </Form.Item>
      </Form>
    </BaseModal>
  );
};

export default LessonEdit;