import api from './base';
import { CreateModuleRequest, Module } from '../../types/courses/module';

export const getAllModules = async (): Promise<Module[]> => {
  const response = await api.get('/modules/all');
  return response.data;
};

export const getModuleById = async (moduleId: string): Promise<Module> => {
  const response = await api.get(`/modules/${moduleId}`);
  return response.data;
};

export const updateModule = async (moduleId: string, data: Partial<Module>): Promise<Module> => {
  const response = await api.patch(`/modules/${moduleId}`, data);
  return response.data;
};

export const createModule = async (data: CreateModuleRequest): Promise<Module> => {
  const response = await api.post('/modules/create', data);
  return response.data;
};