import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { UserOutlined } from '@ant-design/icons';
import { getUserProfile } from '../../services/api';
import styles from './UserProfile.module.css';

export const UserProfile: React.FC<{ collapsed: boolean }> = ({ collapsed }) => {
  const navigate = useNavigate();
  const { data: user } = useQuery({
    queryKey: ['userProfile'],
    queryFn: getUserProfile,
  });

  const handleClick = () => {
    navigate('/profile');
  };

  if (collapsed) {
    return (
      <div className={styles.userProfileCollapsed} onClick={handleClick}>
        <UserOutlined 
          style={{ 
            fontSize: '20px', 
            color: 'rgba(255, 255, 255, 0.85)',
            transition: 'color 0.3s'
          }} 
        />
      </div>
    );
  }

  return (
    <div className={styles.userProfile} onClick={handleClick}>
      <div className={styles.userInfo}>
        <div className={styles.userName}>
          {user?.first_name} {user?.last_name}
        </div>
        {user?.username && (
          <div className={styles.userUsername}>
            @{user.username}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfile;