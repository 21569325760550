import { message } from 'antd';

// Глобальная конфигурация для всех message
export const initializeAntdConfig = () => {
  message.config({
    duration: 4,
    maxCount: 3,
    prefixCls: 'custom-message',
    getContainer: () => document.body,
    top: undefined,
  });
};