import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateCourse } from '../../services/api/courses';
import { Course } from '../../types/courses/course';
import { Module } from '../../types/courses/module';

// Интерфейс для модуля в запросе обновления
interface UpdateModuleRequest {
  id: string;
  position: number;
}

// Интерфейс для данных обновления курса
interface UpdateCourseModulesRequest {
  modules: UpdateModuleRequest[];
}

export const useAddModuleToCourse = (courseId: string, currentCourse: Course) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (moduleToAdd: Module) => {
      try {
        console.log('Current course:', currentCourse);

        // Формируем массив модулей с id и position
        const updatedModules: UpdateModuleRequest[] = [
          ...(currentCourse.modules || []).map(module => ({
            id: module.id,
            position: module.position
          })),
          {
            id: moduleToAdd.id,
            position: (currentCourse.modules?.length || 0) + 1
          }
        ];

        console.log('Sending update with modules:', updatedModules);

        const updateData: UpdateCourseModulesRequest = {
          modules: updatedModules
        };

        return await updateCourse(courseId, updateData);
      } catch (error) {
        console.error('Error in addModuleToCourse:', error);
        throw error;
      }
    },
    onError: (error) => {
      console.error('Mutation error:', error);
    },
    onSuccess: (updatedCourse: Course) => {
      console.log('Successfully updated course:', updatedCourse);
      
      queryClient.setQueryData(['course', courseId], updatedCourse);
      queryClient.invalidateQueries({ queryKey: ['course', courseId] });
      queryClient.invalidateQueries({ queryKey: ['modules'] });
    },
  });
}; 