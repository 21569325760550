import React from 'react';
import { Button, Card, Checkbox } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { Lesson } from '../../../types/courses/lesson';
import commonStyles from '../../../styles/CommonCard.module.css';
import styles from './ModuleLessonCard.module.css';

interface ModuleLessonCardProps {
  lesson: Lesson;
  selected?: boolean;
  isFree?: boolean;
  onClick?: () => void;
  onFreeChange?: (checked: boolean) => void;
  onDetailsClick?: () => void;
}

const ModuleLessonCard: React.FC<ModuleLessonCardProps> = ({ 
  lesson, 
  selected, 
  isFree,
  onClick,
  onFreeChange,
  onDetailsClick
}) => {
  const handleCheckboxClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onFreeChange?.(!isFree);
  };

  return (
    <Card 
      size="small"
      className={`${commonStyles.card} ${styles.moduleLessonCard}`}
      onClick={onClick}
      style={{ 
        borderColor: selected ? '#1677ff' : undefined,
        cursor: 'pointer'
      }}
    >
      <div className={commonStyles.cardContent}>
        <div className={commonStyles.info}>
          <div className={commonStyles.header}>
            <strong>{lesson.title}</strong>
            <div className={styles.actions}>
              {selected && (
                <div 
                  className={styles.checkboxWrapper}
                  onClick={handleCheckboxClick}
                >
                  <Checkbox
                    checked={isFree}
                    onClick={(e) => e.stopPropagation()}
                  >
                    Бесплатный
                  </Checkbox>
                </div>
              )}
              <Button
                type="text"
                size="small"
                icon={<ExportOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  onDetailsClick?.();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ModuleLessonCard;