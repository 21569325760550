import React from 'react';
import { Form, Input, List, Divider, Button, message, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Module } from '../../../types/courses/module';
import { Lesson } from '../../../types/courses/lesson';
import { useLessons } from '../../../hooks/lessons/useLessons';
import BaseModal from '../../common/Modal/BaseModal';
import ModuleLessonCard from './ModuleLessonCard';
import CreateLesson from '../lessons/CreateLesson';
import styles from './ModuleEdit.module.css';
import { useModuleUpdate } from '../../../hooks/modules/useModuleUpdate';

interface ModuleEditProps {
  module: Module;
  onClose: () => void;
  onSave: (values: Partial<Module>) => Promise<void>;
  onFormChange: (changed: boolean) => void;
  onLessonCreate: (newLesson: Lesson) => void;
  onModuleUpdate?: (updatedModule: Module) => void;
  courseId: string;
}

interface ModuleFormValues {
  title: string;
  lessonIds: string[];
  freeLessons: string[];
}

const ModuleEdit: React.FC<ModuleEditProps> = ({ 
  module, 
  onClose, 
  onSave, 
  onFormChange,
  onLessonCreate,
  onModuleUpdate,
  courseId
}) => {
  const [form] = Form.useForm();
  const [isFormChanged, setIsFormChanged] = React.useState(false);
  const [isCreateLessonVisible, setIsCreateLessonVisible] = React.useState(false);
  const [currentModule, setCurrentModule] = React.useState(module);

  const { 
    data: allLessons = [], 
    isLoading: isLessonsLoading,
    refetch: refetchLessons
  } = useLessons(true);

  const moduleUpdate = useModuleUpdate(courseId);

  React.useEffect(() => {
    setCurrentModule(module);
    form.setFieldsValue({
      title: module.title,
      lessonIds: module.lessons?.map(lesson => lesson.id) || [],
      freeLessons: module.lessons?.filter(lesson => lesson.free).map(lesson => lesson.id) || []
    });
    setIsFormChanged(false);
  }, [module, form]);

  const handleValuesChange = () => {
    const formValues = form.getFieldsValue();
    const initialValues = {
      title: currentModule.title,
      lessonIds: currentModule.lessons?.map(lesson => lesson.id) || [],
      freeLessons: currentModule.lessons?.filter(lesson => lesson.free).map(lesson => lesson.id) || []
    };

    const areArraysEqual = (arr1: any[], arr2: any[]) => {
      if (!arr1 || !arr2) return false;
      if (arr1.length !== arr2.length) return false;
      return arr1.every((item) => arr2.includes(item)) && 
             arr2.every((item) => arr1.includes(item));
    };

    const hasChanges = 
      formValues.title !== initialValues.title ||
      !areArraysEqual(formValues.lessonIds, initialValues.lessonIds) ||
      !areArraysEqual(formValues.freeLessons, initialValues.freeLessons);

    setIsFormChanged(hasChanges);
    onFormChange(hasChanges);
  };

  const handleLessonClick = (lessonId: string) => {
    const currentLessonIds = form.getFieldValue('lessonIds') || [];
    const newLessonIds = currentLessonIds.includes(lessonId)
      ? currentLessonIds.filter((id: string) => id !== lessonId)
      : [...currentLessonIds, lessonId];
    
    form.setFieldValue('lessonIds', newLessonIds);
    handleValuesChange();
  };

  const handleLessonFreeChange = (lessonId: string, isFree: boolean) => {
    const currentValues = form.getFieldsValue();
    const currentFreeLessons = currentValues.freeLessons || [];
    
    const newFreeLessons = isFree 
      ? [...currentFreeLessons, lessonId]
      : currentFreeLessons.filter((id: string) => id !== lessonId);

    form.setFieldsValue({
      ...currentValues,
      freeLessons: newFreeLessons
    });
    handleValuesChange();
  };

  const handleSubmit = async (values: ModuleFormValues) => {
    try {
      const updatedModule: Partial<Module> = {
        title: values.title,
        lessons: values.lessonIds.map((id, index) => ({
          id: id,
          position: index + 1,
          free: values.freeLessons?.includes(id) || false
        }))
      };
      
      await moduleUpdate.mutateAsync({
        moduleId: module.id,
        values: updatedModule
      });
      
      message.success('Изменения сохранены');
      
      const newModule = {
        ...currentModule,
        ...updatedModule
      };
      
      setCurrentModule(newModule);
      form.setFieldsValue(newModule);
      setIsFormChanged(false);
      onFormChange(false);
      
      if (onModuleUpdate) {
        onModuleUpdate(newModule);
      }

    } catch (error) {
      message.error('Не удалось сохранить изменения');
    }
  };

  const handleLessonCreate = async (newLesson: Lesson) => {
    setIsCreateLessonVisible(false);
    const currentLessonIds = form.getFieldValue('lessonIds') || [];
    form.setFieldValue('lessonIds', [...currentLessonIds, newLesson.id]);
    onLessonCreate(newLesson);
    await refetchLessons();
  };

  const watchedLessonIds = Form.useWatch('lessonIds', form);
  const selectedLessonIds = React.useMemo(() => 
    Array.isArray(watchedLessonIds) ? watchedLessonIds : [], 
    [watchedLessonIds]
  );
  
  const watchedFreeLessons = Form.useWatch('freeLessons', form);
  const freeLessons = React.useMemo(() => 
    Array.isArray(watchedFreeLessons) ? watchedFreeLessons : [], 
    [watchedFreeLessons]
  );

  const sortedLessons = React.useMemo(() => {
    const selectedLessons = selectedLessonIds
      .map(id => allLessons.find((l: Lesson) => l.id === id))
      .filter((l): l is Lesson => l !== undefined);
    
    const unselectedLessons = allLessons.filter(
      (lesson: Lesson) => !selectedLessonIds.includes(lesson.id)
    );
    
    return [...selectedLessons, ...unselectedLessons];
  }, [selectedLessonIds, allLessons]);

  const renderLessonsList = () => {
    if (isLessonsLoading) {
      return <Spin size="large" />;
    }

    return (
      <List
        dataSource={sortedLessons}
        split={false}
        renderItem={(lesson: Lesson, index: number) => (
          <>
            {index === selectedLessonIds.length && selectedLessonIds.length > 0 && (
              <Divider>Все уроки</Divider>
            )}
            <ModuleLessonCard
              key={lesson.id}
              lesson={lesson}
              selected={selectedLessonIds.includes(lesson.id)}
              onClick={() => handleLessonClick(lesson.id)}
              onFreeChange={(isFree) => handleLessonFreeChange(lesson.id, isFree)}
              isFree={freeLessons.includes(lesson.id)}
            />
          </>
        )}
      />
    );
  };

  return (
    <BaseModal
      title="Редактирование модуля"
      onClose={onClose}
      form={form}
      isFormChanged={isFormChanged}
      primaryButton={{
        text: 'Сохранить',
        type: 'primary',
        onClick: () => form.submit()
      }}
      secondaryButton={{
        text: 'Отмена',
        onClick: onClose
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          title: currentModule.title,
          lessonIds: currentModule.lessons?.map(lesson => lesson.id) || [],
          freeLessons: currentModule.lessons?.filter(lesson => lesson.free).map(lesson => lesson.id) || []
        }}
        onFinish={handleSubmit}
        onValuesChange={handleValuesChange}
      >
        <Form.Item
          name="title"
          label="Название модуля"
          rules={[{ required: true, message: 'Введите название модуля' }]}
        >
          <Input placeholder="Введите название модуля" />
        </Form.Item>

        <Form.Item
          name="lessonIds"
          label="Уроки"
          rules={[{ required: true, message: 'Выберите хотя бы один урок' }]}
        >
          <div className={styles.lessonsContainer}>
            <Button 
              type="dashed" 
              icon={<PlusOutlined />}
              onClick={() => setIsCreateLessonVisible(true)}
              className={styles.createButton}
            >
              Создать урок
            </Button>
            {renderLessonsList()}
          </div>
        </Form.Item>

        <Form.Item name="freeLessons" hidden>
          <Input />
        </Form.Item>
      </Form>

      <CreateLesson
        visible={isCreateLessonVisible}
        onCancel={() => setIsCreateLessonVisible(false)}
        onCreate={handleLessonCreate}
      />
    </BaseModal>
  );
};

export default ModuleEdit;