import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, Tabs, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useCategories } from '../../hooks/catalog/useCategories';
import { useSubcategories } from '../../hooks/catalog/useSubcategories';
import { 
  Category, 
  Subcategory, 
  CategoryTableItem, 
  SubcategoryTableItem 
} from '../../types/catalog';
import { 
  CategoryTable, 
  SubcategoryTable, 
  CategoryDrawer, 
  SubcategoryDrawer 
} from '../../components/Categories';
import styles from './Catalog.module.css';
import commonStyles from '../../styles/CommonPage.module.css';

const { Title } = Typography;
const { TabPane } = Tabs;

const CategoriesPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState<Subcategory | null>(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [activeTab, setActiveTab] = useState(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('tab') || 'categories';
  });
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});

  // Обновляем URL при изменении вкладки
  const handleTabChange = (key: string) => {
    setActiveTab(key);
    navigate({
      pathname: location.pathname,
      search: `?tab=${key}`
    });
  };

  // Синхронизируем состояние с URL при изменении location
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setActiveTab(searchParams.get('tab') || 'categories');
  }, [location.search]);

  const { 
    categories, 
    createCategory, 
    updateCategory, 
    isLoading: categoriesLoading 
  } = useCategories();

  const { 
    subcategories, 
    createSubcategory, 
    updateSubcategory, 
    isLoading: subcategoriesLoading 
  } = useSubcategories();

  const categoryData: CategoryTableItem[] = categories.map(category => ({
    key: category.id,
    title: category.title,
    position: category.position,
    subcategoriesCount: category.subcategories?.length || 0,
    hasImage: !!category.image,
  }));

  const subcategoryData: SubcategoryTableItem[] = subcategories.map(subcategory => ({
    key: subcategory.id,
    title: subcategory.title,
    coursesCount: subcategory.courses?.length || 0,
  }));

  const handleCreate = () => {
    setSelectedCategory(null);
    setSelectedSubcategory(null);
    setDrawerVisible(true);
  };

  const handleCategorySubmit = async (values: any) => {
    try {
      setValidationErrors({});
      if (selectedCategory) {
        const updatedCategory = await updateCategory({ id: selectedCategory.id, data: values });
        setSelectedCategory(updatedCategory);
        setDrawerVisible(true);
      } else {
        await createCategory(values);
        setDrawerVisible(false);
      }
    } catch (error: any) {
      if (error.response?.status === 422) {
        const validationResponse = error.response.data;
        const errors: Record<string, string> = {};
        validationResponse.detail.forEach((error: any) => {
          errors[error.loc[1]] = error.msg;
        });
        setValidationErrors(errors);
      }
    }
  };

  const handleSubcategorySubmit = async (values: any) => {
    try {
      setValidationErrors({});
      if (selectedSubcategory) {
        await updateSubcategory({ id: selectedSubcategory.id, data: values });
      } else {
        await createSubcategory(values);
      }
      setDrawerVisible(false);
    } catch (error: any) {
      if (error.response?.status === 422) {
        const validationResponse = error.response.data;
        const errors: Record<string, string> = {};
        validationResponse.detail.forEach((error: any) => {
          errors[error.loc[1]] = error.msg;
        });
        setValidationErrors(errors);
      }
    }
  };

  return (
    <div className={commonStyles.pageContainer}>
      <div className={styles.pageHeader}>
        <Title level={2} className={commonStyles.pageTitle}>
          Каталог
        </Title>
        <Button 
          type="primary" 
          icon={<PlusOutlined />}
          onClick={handleCreate}
          className={styles.createButton}
        >
          Создать {activeTab === 'categories' ? 'категорию' : 'подкатегорию'}
        </Button>
      </div>

      <div className={commonStyles.pageContent}>
        <Tabs 
          activeKey={activeTab}
          onChange={handleTabChange}
        >
          <TabPane tab="Категории" key="categories">
            <CategoryTable 
              data={categoryData}
              onRowClick={(id: string) => {
                const category = categories.find(cat => cat.id === id);
                if (category) {
                  setSelectedCategory(category);
                  setDrawerVisible(true);
                }
              }}
            />
          </TabPane>
          <TabPane tab="Подкатегории" key="subcategories">
            <SubcategoryTable 
              data={subcategoryData}
              onRowClick={(id: string) => {
                const subcategory = subcategories.find(sub => sub.id === id);
                if (subcategory) {
                  setSelectedSubcategory(subcategory);
                  setDrawerVisible(true);
                }
              }}
            />
          </TabPane>
        </Tabs>
      </div>

      {activeTab === 'categories' ? (
        <CategoryDrawer
          visible={drawerVisible}
          category={selectedCategory}
          onClose={() => {
            setDrawerVisible(false);
            setSelectedCategory(null);
            setValidationErrors({});
          }}
          onSubmit={handleCategorySubmit}
          loading={categoriesLoading}
          validationErrors={validationErrors}
        />
      ) : (
        <SubcategoryDrawer
          visible={drawerVisible}
          subcategory={selectedSubcategory}
          onClose={() => {
            setDrawerVisible(false);
            setSelectedSubcategory(null);
            setValidationErrors({});
          }}
          onSubmit={handleSubcategorySubmit}
          loading={subcategoriesLoading}
          validationErrors={validationErrors}
        />
      )}
    </div>
  );
};

export default CategoriesPage;