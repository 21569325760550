import { useQuery } from '@tanstack/react-query';
import { getCourseById } from '../../services/api/courses';
import { Course } from '../../types/courses/course';

export const useCourseDetails = (courseId: string, includeModules = false) => {
  const query = useQuery<Course, Error, Course>({
    queryKey: ['course', courseId, { includeModules }],
    queryFn: () => getCourseById(courseId),
    enabled: !!courseId,
    staleTime: 0,
    gcTime: 0,
  });

  console.log('useQuery state:', {
    data: query.data,
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error
  });

  return query;
};