import api from './base';
import { CreateLessonRequest, Lesson } from '../../types/courses/lesson';

export const getAllLessons = async (): Promise<Lesson[]> => {
  const response = await api.get('/lessons/all');
  return response.data;
};

export const getLessonById = async (lessonId: string): Promise<Lesson> => {
  const response = await api.get(`/lessons/${lessonId}`);
  return response.data;
};

export const updateLesson = async (lessonId: string, data: Partial<Lesson>): Promise<Lesson> => {
  const response = await api.patch(`/lessons/${lessonId}`, data);
  return response.data;
};

export const createLesson = async (data: CreateLessonRequest): Promise<Lesson> => {
  const response = await api.post('/lessons/create', data);
  return response.data;
};