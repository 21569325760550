import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { Sidebar } from '../components/Sidebar';
import styles from './MainLayout.module.css';

const { Content } = Layout;

const MainLayout: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const siderWidth = collapsed ? 80 : 200;

  useEffect(() => {
    document.documentElement.style.setProperty('--sider-width', `${siderWidth}px`);
  }, [siderWidth]);

  return (
    <Layout className={styles.layout}>
      <Sidebar
        collapsed={collapsed}
        siderWidth={siderWidth}
        onCollapse={setCollapsed}
      />

      <Layout 
        className={styles.content}
        style={{ marginLeft: siderWidth }}
      >
        <Content className={styles.mainContent}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;