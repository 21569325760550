import React, { useCallback, useState } from 'react';
import { Upload, Spin, Button, message } from 'antd';
import { 
  InboxOutlined, 
  DeleteOutlined, 
  ExclamationCircleOutlined 
} from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { uploadFile } from '../../services/api';
import { FilePurpose, UploadedFile } from '../../types/files';
import styles from './FileUpload.module.css';

const { Dragger } = Upload;

interface FileUploadProps {
  purpose: FilePurpose;
  onChange?: (fileUrl: string) => void;
  value?: string;
}

const FileUpload: React.FC<FileUploadProps> = ({ purpose, onChange, value }) => {
  const [error, setError] = useState<string | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const uploadMutation = useMutation({
    mutationFn: (file: File) => uploadFile(file, purpose),
    onSuccess: (data: UploadedFile) => {
      const fileUrl = `${process.env.REACT_APP_BACKEND_URL || 'http://localhost:9000'}/${process.env.REACT_APP_MINIO_BUCKET || 'public-local'}/${data.s3_key}`;
      onChange?.(fileUrl);
      setError(null);
      message.success('Файл успешно загружен');
    },
    onError: () => {
      setError('Ошибка при загрузке файла');
      message.error('Не удалось загрузить файл');
    },
  });

  const handleUpload = useCallback(async (file: File) => {
    if (!file.type.startsWith('image/')) {
      message.error('Можно загружать только изображения');
      return false;
    }

    // Создаем превью сразу после выбора файла
    const reader = new FileReader();
    reader.onload = (e) => {
      setPreviewUrl(e.target?.result as string);
    };
    reader.readAsDataURL(file);
    
    try {
      await uploadMutation.mutateAsync(file);
    } catch (error) {
      console.error('Ошибка при загрузке файла:', error);
    }
    return false;
  }, [uploadMutation]);

  const handleDelete = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    onChange?.('');
    setPreviewUrl(null);
    setError(null);
  }, [onChange]);

  if (value || previewUrl) {
    return (
      <div className={styles.fileCard}>
        <img 
          src={value || previewUrl || ''} 
          alt="Preview" 
          className={styles.fileCardImage}
        />
        <Button
          className={styles.deleteButton}
          icon={<DeleteOutlined />}
          onClick={handleDelete}
        />
        {uploadMutation.isPending && (
          <div className={styles.loadingOverlay}>
            <Spin size="large" />
          </div>
        )}
        {error && (
          <div className={styles.errorOverlay}>
            <ExclamationCircleOutlined className={styles.errorIcon} />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Dragger
        showUploadList={false}
        beforeUpload={handleUpload}
        accept="image/*"
        className={styles.dragger}
      >
        <div className={styles.uploadContainer}>
          <InboxOutlined className={styles.uploadIcon} />
          <p className={styles.uploadText}>
            Нажмите или перетащите файл для загрузки
          </p>
          <p className={styles.uploadHint}>
            Поддерживаются только изображения
          </p>
        </div>
      </Dragger>
    </div>
  );
};

export default FileUpload;