import { useQuery } from '@tanstack/react-query';
import { getAllCourses } from '../../services/api/courses';

export const useCourses = () => {
  const { 
    data: courses = [], 
    isLoading,
    error 
  } = useQuery({
    queryKey: ['courses'],
    queryFn: getAllCourses
  });

  return {
    courses,
    isLoading,
    error
  };
};