import api from './base';
import { Course, UpdateCourseRequest } from '../../types/courses/course';

export const getAllCourses = async (): Promise<Course[]> => {
  const response = await api.get('/courses/all');
  return response.data;
};

export const getCourseById = async (id: string): Promise<Course> => {
  console.log('Fetching course with ID:', id);
  const response = await api.get(`/courses/${id}`);
  console.log('API Response:', response.data);
  return response.data;
};

export const updateCourse = async (courseId: string, data: UpdateCourseRequest): Promise<Course> => {
  const response = await api.patch(`/courses/${courseId}`, data);
  return response.data;
};