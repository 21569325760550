import React from 'react';
import { Form, Input, List, Divider, FormInstance } from 'antd';
import { useCourses } from '../../../hooks/courses/useCourses';
import CourseCard from '../common/CourseCard';
import { Course } from '../../../types/courses/course';

interface SubcategoryFormProps {
  form: FormInstance;
  validationErrors: Record<string, string>;
}

const SubcategoryForm: React.FC<SubcategoryFormProps> = ({ form, validationErrors }) => {
  const { courses } = useCourses();
  const selectedCourseIds = Form.useWatch('courseIds', form) || [];

  const handleCourseClick = (courseId: string) => {
    const currentCourseIds = form.getFieldValue('courseIds') || [];
    const newCourseIds = currentCourseIds.includes(courseId)
      ? currentCourseIds.filter((id: string) => id !== courseId)
      : [...currentCourseIds, courseId];
    
    form.setFieldValue('courseIds', newCourseIds);
  };

  const sortedCourses = [
    ...selectedCourseIds.map((id: string) => courses.find((c: Course) => c.id === id)).filter(Boolean),
    ...courses.filter((course: Course) => !selectedCourseIds.includes(course.id))
  ];

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{ title: '', courseIds: [] }}
    >
      <Form.Item
        name="title"
        label="Название"
        validateStatus={validationErrors.title ? 'error' : ''}
        help={validationErrors.title}
        rules={[{ required: true, message: 'Введите название подкатегории' }]}
      >
        <Input placeholder="Введите название подкатегории" />
      </Form.Item>

      <Form.Item
        name="courseIds"
        label="Курсы"
        rules={[{ required: true, message: 'Выберите хотя бы один курс' }]}
        trigger="onChange"
        validateTrigger="onChange"
      >
        <List
          dataSource={sortedCourses}
          split={false}
          renderItem={(course: Course, index: number) => (
            <>
              {index === selectedCourseIds.length && selectedCourseIds.length > 0 && (
                <Divider>Все курсы</Divider>
              )}
              <CourseCard
                key={course.id}
                course={course}
                selected={selectedCourseIds.includes(course.id)}
                onClick={() => handleCourseClick(course.id)}
              />
            </>
          )}
        />
      </Form.Item>
    </Form>
  );
};

export default SubcategoryForm;